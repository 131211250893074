




































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
	INDICATOR_REGIONAL_PASSPORT_UPDATE,
	INDICATOR_REGIONAL_PASSPORT_CREATE,
	INDICATOR_REGIONAL_PASSPORT_DELETE,
	INDICATOR_REGIONAL_PASSPORT_ATTACHMENT_DELETE,
} from "@/store/actions/indicator";
import { RegionalPassport } from "@/models/Indicator";
import { Region } from "@/models/Region";
import {
  REGION_REQUEST,
  REGION_UPDATED,
  REGION_LOADED,
} from "@/store/actions/main";

@Component({})
export default class RegionalPassportForm extends Vue {
  private form: any = {};
	private files: any = [];
	private regions: any = [];
  private loading: boolean = false;
  private menu = false;
  //attachments table
  private attachments: any = [];
  private attachmentsHeaders = [
    {
      text: "Наименование",
      value: "path",
      align: "start",
      width: "35%",
    },
    {
      text: "Действия",
      value: "actions",
      align: "center",
      sortable: false,
      width: "10%",
    },
  ];
  private search = "";
  private tablePage = 1;
  private tablePageCount = 0;
  private itemsPerPage = -1;
  private itemToDelete: any = {} as any;
  private deleteDialog: boolean = false;
  private deleteSnackbar: boolean = false;
  private deleteDisabled: boolean = false;
  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
		needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
		if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
    if (this.initIfReady()) return;
  }
  private initSelf() {
    console.log("INIT SELF upd");
		this.regions = this.$store.getters.REGION_TABLE.data;
    if(this.isUpdatePage()){
      this.initStartOptions();
    }
    this.loading = false;
    console.log("loading");
  }
  //Methods
	private getRegion(id){
    for(let i = 0; i < this.regions.length; i++){
      if(id == this.regions[i].id)
        return this.regions[i].title
    }
  }
  private isDeleting(id) {
    if (this.itemToDelete.id == id) return true;
    return false;
  }
  private cleanDeleting() {
    this.itemToDelete = {} as any;
  }
  private editItem() {
    var converted = new FormData();
    delete this.form.attachments;
    delete this.form.id;
    Object.keys(this.form).forEach((key) => {
      console.log(key);
      converted.append(key, this.form[key]);
    });
    for(let i = 0; i < this.files.length; i++){
      converted.append("files", this.files[i]);
    }
    var data = {
      id: +this.$route.params.id,
      data: converted,
    };
    console.log("form", this.form);
    console.log("files", this.files);
    this.$store
      .dispatch(INDICATOR_REGIONAL_PASSPORT_UPDATE, data)
      .then(() => {
        this.messageText = "Региональный паспорт изменен.";
        this.messageColor = "green";
        let parsed = this.toString(localStorage.getItem("regional"));
        this.form = JSON.parse(parsed);
        delete this.form.files;
        this.attachments.length = 0;
        for(let i = 0; i < this.form.attachments.length; i++){
          this.attachments.push({
            id: +this.form.attachments[i].id,
            indicator_id: +this.form.attachments[i].indicator_id,
            path: ""+this.form.attachments[i].path,
            });
        }
        this.files = [] as any;
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private createItem() {
    var converted = new FormData();
    delete this.form.attachments;
    delete this.form.id;
    Object.keys(this.form).forEach((key) => {
      console.log(key);
      converted.append(key, this.form[key]);
    });
    for(let i = 0; i < this.files.length; i++){
      converted.append("files", this.files[i]);
    }
    var data = {
      id: +this.$route.params.id,
      data: converted,
    };
    console.log("form", this.form);
    console.log("files", this.files);
    this.$store
      .dispatch(INDICATOR_REGIONAL_PASSPORT_UPDATE, data)
      .then(() => {
        this.messageText = "Региональный паспорт изменен.";
        this.messageColor = "green";
        let parsed = this.toString(localStorage.getItem("regional"));
        this.form = JSON.parse(parsed);
        delete this.form.files;
        this.attachments.length = 0;
        for(let i = 0; i < this.form.attachments.length; i++){
          this.attachments.push({
            id: +this.form.attachments[i].id,
            indicator_id: +this.form.attachments[i].indicator_id,
            path: ""+this.form.attachments[i].path,
            });
        }
        this.files = [] as any;
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private toString(str) {
    return str as string;
  }
  private isUpdatePage() {
    return this.$route.meta.isUpdate;
  }
  private initStartOptions() {
    var item;
    if (localStorage.getItem("regionalPassport")) {
      console.log("found data in local storage");
      try {
        item = JSON.parse(
          this.toString(localStorage.getItem("regionalPassport"))
        );
        console.log("item init: ", item);
      } catch (e) {
        localStorage.removeItem("regionalPassport");
      }
    } else {
      console.log("not found data in local storage");
    }
    Object.keys(item).forEach((key) => {
      this.form[key] = item[key];
    });
    this.attachments = item.attachments;
    delete this.form.indicator_id;
    let arr = this.form.date_update.split("T");
    this.form.date_update = arr[0];
    this.$forceUpdate();
    console.log("form: ", this.form);
  }
  private deleteAttachment(item: any, confirmation: boolean) {
    this.itemToDelete = item;
    if (confirmation) {
      this.deleteDialog = true;
    } else {
      console.log("deleting", item);
      this.$store.dispatch(INDICATOR_REGIONAL_PASSPORT_ATTACHMENT_DELETE, item).then(() => {
        //this.tryToInitSelf();
        for(let i = 0; i < this.attachments.length; i++){
          if(this.attachments[i].id == item.id){
            this.attachments.splice(i, 1);
          }
        }
        this.deleteSnackbar = true;
        this.itemToDelete = item;
        this.deleteDisabled = false;
      });
      this.deleteDialog = false;
    }
  }
  private downloadFile(item: any){
    window.open(process.env.VUE_APP_API_ENDPOINT + "/" + item.path);
  }
  private getFileName(str){
    let arr = str.split("\\");
    let name = "";
    if(arr.length == 3)
      name = arr[2];
    else
      name = str;
    return name;
  }
  //Hooks
  private created() {
    if(this.isUpdatePage()){
      if(localStorage.getItem("regionalPassport")){
    	let item = JSON.parse(this.toString(localStorage.getItem("regionalPassport")));
			this.$router.currentRoute.meta.breadcrumb[4].text = item.title;
			//this.$router.currentRoute.meta.breadcrumb[2].href = "/#/pages/indicators/regionalPassports/"+item.indicator_id;
      }
    }
    if(localStorage.getItem("regionalIndicatorTitle")){
      let title = localStorage.getItem("regionalIndicatorTitle");
      this.$router.currentRoute.meta.breadcrumb[2].text = title;
    }
  }
  private mounted() {
    this.tryToInitSelf();
  }
  private destroyed() {
    //localStorage.removeItem("regionalPassport");
  }
}
